import { defineStore } from "pinia";
export const useSession = defineStore("session", {
	state: () => ({
		sessions: [],
		tabBlock: false,
		maxSessions: 1,
	}),
	actions: {
		setSessions(value) {
			this.sessions = value;
		},
		setTabBlock(value) {
			this.tabBlock = value;
		},
		setMaxSessions(value) {
			this.maxSessions = value;
		},
	},
	getters: {
		getSessions(state) {
			return state.sessions;
		},
		getTabBlock(state) {
			return state.tabBlock;
		},
		getMaxSessions(state) {
			return state.maxSessions;
		},
	},
});
