<template>
	<NuxtLoadingIndicator :color="'var(--highlight-bg)'" />

	<NuxtLayout>
		<NuxtPage />
	</NuxtLayout>
</template>

<script setup>
	import { useSession } from "~/store/session/session.js";

	const sessionStore = useSession();
	const { getMaxSessions, getSessions } = storeToRefs(sessionStore);

	onMounted(() => {
		const sessionStorage = window.sessionStorage;
		let id = sessionStorage.getItem("tabs_id");

		if (!id) {
			const newId = Date.now();

			sessionStorage.setItem("tabs_id", `${newId}`);

			id = newId;
		}

		const bc = new BroadcastChannel("app_channel");
		sessionStorage.setItem("tabs_parent", "1");

		bc.onmessage = (event) => {
			if (event.data.type === "ping") {
				bc.postMessage({ type: "open", id, parent: sessionStorage.getItem("tabs_parent") });

				return;
			}

			console.log(getSessions.value.length);
			console.log(getMaxSessions.value);

			if ((id > event.data.id || event.data.parent) && getSessions.value.length >= getMaxSessions.value) {
				sessionStore.setTabBlock(true);
				sessionStorage.removeItem("tabs_parent");
			}
		};

		bc.postMessage({ type: "ping" });
	});
</script>
