/* global useAuth */
import { defineNuxtRouteMiddleware, useRuntimeConfig } from "#app";
import { useSession } from "~/store/session/session.js";
import { $useFetch } from "~/composables/useFetch.js";
import { api } from "~/api/api.js";

export default defineNuxtRouteMiddleware(async (to) => {
	const { signOut, status } = useAuth();
	const { KEY_CLOAK_LOGOUT_URL, KEY_CLOAK_REDIRECT_URI } = useRuntimeConfig().public;

	if (status.value === "unauthenticated" || to.path === "/login") return;

	const sessionStore = useSession();

	const { data: userSessions, error } = await $useFetch(api.userInfo, {
		key: "user-info-me",
		method: "GET",
		transform: (mutations) => ({
			sessions: mutations.data.attributes.sessions,
			max_sessions: 1,
		}),
	});

	if (error.value && error.value.statusCode === 403) {
		await signOut({
			redirect: false,
		});
		window.location.href = `${KEY_CLOAK_LOGOUT_URL}${KEY_CLOAK_REDIRECT_URI}`;
	}

	sessionStore.setSessions(userSessions.value.sessions);
	sessionStore.setMaxSessions(userSessions.value.max_sessions);
});
