const v1 = "/api/v1";

export const api = {
	login: `${v1}/user/oauth/token`,
	refreshToken: `${v1}/user/oauth/refresh`,
	userInfo: `${v1}/user/me`,
	deleteSession: `${v1}/user/me/sessions`,
	changePasswordMe: `${v1}/user/me/change-password`,
	userDetail: (id) => `${v1}/user/${id}`,
	product: (id) => `${v1}/products/${id}`,
	productApplicableModels: (id) => `${v1}/products/${id}/applicablemodels`,
	productsAnalogsAll: (id) => `${v1}/products/${id}/analogs`,
	productAnalogs: (id) => `${v1}/products/${id}/analogs`,
	roles: `${v1}/user/roles`,
	productSearch: `${v1}/products/search`,
	productSearchFile: `${v1}/products/search/file`,
	jvCodesList: `${v1}/jvcodes`,
	orderList: `${v1}/orders`,
	orderTypes: `${v1}/ordertypes`,
	orderStatuses: `${v1}/orderstatuses`,
	orders: `${v1}/orders`,
	ordersDetail: (id) => `${v1}/orders/${id}`,
	orderCancel: (id) => `${v1}/orders/${id}/cancel`,
	shippingModes: `${v1}/shippingmodes`,
	shipsList: `${v1}/ships`,
	customersInfo: (id) => `${v1}/customers/${id}`,
	allocations: `${v1}/allocations`,
	allocationsInfo: (id) => `${v1}/allocations/${id}`,
	users: `${v1}/users`,
	dealers: `${v1}/contragents`,
	dealer: (id) => `${v1}/contragents/${id}`,
	customers: (id) => `${v1}/contragents/${id}/customers`,
	homeContent: `${v1}/pages/main`,
	promotion: (id) => `${v1}/actions/${id}`,
	filePromotion: (customer) => `${v1}/customers/${customer}/prices`,
	fileAllPrice: `${v1}/products/prices`,
};
