import validate from "/opt/docker/node_modules/nuxt/dist/pages/runtime/validate.js";
import cookies_45global from "/opt/docker/middleware/cookies.global.js";
import roles_45global from "/opt/docker/middleware/roles.global.js";
import sessi_45global from "/opt/docker/middleware/sessi.global.js";
import manifest_45route_45rule from "/opt/docker/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  cookies_45global,
  roles_45global,
  sessi_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  product: () => import("/opt/docker/middleware/product.js"),
  auth: () => import("/opt/docker/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.mjs")
}